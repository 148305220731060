<template>
    <div class="auth-page">
        <div class="full-img">
            <img src="/assets/img/vuexy-login-bg.jpg" class="img-fluid" alt="">
        </div>
        <ValidationObserver ref="observer">
            <div class="login-form" v-if="state != 1">
                <router-link to="/login"><i class="fa fa-arrow-left"></i> Back to Login</router-link>
                <div class="form-logo">
                    <img src="/assets/img/logo-diri-x4.png" class="img-fluid" alt="">
                </div>
                <p class="text-blue font-500 f-18 mb-0">Reset Password</p>
                <p class="mb-2">Please enter your new password below.</p>
                
                <form @submit.prevent="reset">
                    <ValidationProvider name="password" rules="required|min:6" v-slot="{ errors }">
                        <div class="col p-0">
                            <div class="form-group input-group mb-0" :class="{ 'validation-warning': errors[0] }">
                                <input :type="showPassword_1 ? 'text' : 'password'" id="password" class="form-control border-right-0" required v-model="model.password" placeholder="Input Password">
                                <label class="form-control-placeholder" for="password">PASSWORD</label>
                                <span class="input-group-append">
                                    <div class="input-group-text">
                                        <a href="javascript:;" @click="showPassword_1 = !showPassword_1" class="text-blue">
                                            <i :class="showPassword_1 ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                                        </a>
                                    </div>
                                </span>
                            </div>
                            <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                        </div>
                    </ValidationProvider>
                    <ValidationProvider name="password confirmation" rules="required|confirmed:password" v-slot="{ errors }">
                        <div class="col p-0">
                            <div class="form-group input-group mb-0 mt-1" :class="{ 'validation-warning': errors[0] }">
                                <input :type="showPassword_2 ? 'text' : 'password'" id="password" class="form-control border-right-0" required v-model="model.password_confirmation" placeholder="Input Password Confirmation">
                                <label class="form-control-placeholder" for="password">PASSWORD CONFIRMATION</label>
                                <span class="input-group-append">
                                    <div class="input-group-text">
                                        <a href="javascript:;" @click="showPassword_2 = !showPassword_2" class="text-blue">
                                            <i :class="showPassword_2 ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                                        </a>
                                    </div>
                                </span>
                            </div>
                            <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                        </div>
                    </ValidationProvider>
                    <p class="mt-1"><small><b>Hint</b>: The password should be at least 8 characters long. To make it stronger, use upper and lower case letters, numbers, and symbol like ! " ? $ % ^ )</small></p>
                    <button type="submit" class="btn btn-primary waves-effect waves-light w-100">Reset Password</button>
                    <p class="val-error text-center mt-1" v-if="state == 0">Failed to reset email, please connect to our customer service.</p>
                </form>
            </div>
            <div class="login-form" v-else-if="state == 1">
                <div class="form-logo">
                    <img src="/assets/img/logo-posind-dark-w-192.png" class="img-fluid" alt="">
                </div>
                <p>Successfully reset your password, please click this button below to go to login page.</p>
                <router-link to="/login">
                    <button class="btn btn-primary waves-effect waves-light w-100 mt-2">Login</button>
                </router-link>
            </div>            
        </ValidationObserver>  
    </div> 
</template>

<script>
export default {
	name: 'Login',
    data() {
        return {
            model: {
                password: '',
                password_confirmation: ''
            },
            showPassword_1: false,
            showPassword_2: false,
            state: -1
        }
    },
	methods: {
		async reset() {
            let _ = this
            const isValid = await this.$refs.observer.validate()
            if (isValid){
                _.axios.post('/auth/reset-password', { 
                    username: _.$route.query.username,
                    token: _.$route.query.token, 
                    password: _.model.password
                }).then(resp=>{
                    if (resp.data.status){
                        _.state = 1
                    } else {
                        _.state = 0
                    }
                })
                .catch(err=>{
                    console.log(err.message)
                })
            }
		}
	}
}
</script>